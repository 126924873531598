<template>
  <div class="app-container">
    <div>
      <el-cascader
        v-model="value"
        :options="options"
        :props="serCitProp"
        @change="handleChange"
      ></el-cascader>
    </div>
    <div style="margin-bottom: 20px;margin-top: 10px;">
      <el-row :gutter="40" type="flex" justify="center">
        <el-col :span="24" style="text-align:center;">
          <el-card v-for="(item,treeType) in imuLeixing" :key="treeType" style="display: inline-block;margin: 1%;width: 18%;cursor: pointer;" @click="changeTimuleixing(item)">
            <div style="text-align: center;">
              <img
              src="@/assets/zhuce.png"
              class="image"
              style="width: 30%"
            />
            </div>
            <div style="text-align: center;">
              <span :class="{active: isActive === item.treeType}">{{ item.name }}</span>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div>
      <!-- 题 -->
      <el-table
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        :height="contentStyleObj"
      >
        <el-table-column prop="name" :label="this.timuLeixingName" sortable min-width="280">
        </el-table-column>
        <!-- <el-table-column prop="name" label="已做/总题" sortable min-width="100">
          <template #default="scope">
            {{ scope.row.name+'/'+scope.row.total }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="错题" sortable min-width="80">
        </el-table-column> -->
        <el-table-column  label="操作" width="120">
          <template #default="scope">
            <el-button v-if="!scope.row.children" type="primary" plain @click="startExam(scope.row)">开始做题</el-button>
            <!-- <el-button style="float: right;" type="danger" plain>重新做题</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { TreeList } from "@/api/example"

export default {
  name: 'example2',

  data() {
    return {
      tableData: [],
      value: 3,
      options:[
        {
          value: 1,
          label: '初级会计师',
          children: [
            {
              value: 3,
              label: '初级会计实务',
            },
            {
              value: 4,
              label: '经济法基础',
            },
          ],
        },
        {
          value: 2,
          label: '中级会计师',
          children: [
            {
              value: 5,
              label: '中级财务管理',
            },
            {
              value: 6,
              label: '中级会计实务',
            },
            {
              value: 7,
              label: '中级经济法',
            },
          ],
        },
        {
          value: 'zhinan',
          label: '注册会计师',
          children: [
            {
              value: 'shejiyuanze',
              label: '审计',
            },
            {
              value: 'daohang',
              label: '税法',
            },
            {
              value: 'daohang',
              label: '会计',
            },
            {
              value: 'shejiyuanze',
              label: '经济法',
            },
            {
              value: 'daohang',
              label: '财务成本管理',
            },
            {
              value: 'daohang',
              label: '战略与风险管理',
            },
            {
              value: 'daohang',
              label: '综合一',
            },
            {
              value: 'daohang',
              label: '综合二',
            },
          ],
        },
      ],
      contentStyleObj:{},
      imuLeixing:[
        {
          name:'章节练习',
          treeType:0
        },
        {
          name:'专项练习',
          treeType:1
        },
        {
          name:'模拟考场',
          treeType:2
        },
        {
          name:'考前预测',
          treeType:3
        },
        {
          name:'历年真题',
          treeType:4
        }
      ],
      timuLeixingName:'章节练习',
      isActive:0,
      treeType:0,
      // 这个属性可以只选最后一级
      serCitProp:{
        emitPath:false
      },
      initId:0
    };
  },
  created(){
    this.contentStyleObj= this.$getHeight(400)
    this.getList()
    this.init()
  },

  mounted() {
    
  },

  methods: {
    // init接口
    init(){
      let id = this.$store.getters["commons/params"].exampleId
      this.value = id
    },
    getList(valId){
      let id = this.$store.getters["commons/params"].exampleId
      if(this.value){
        this.initId = this.value
      }else{
        this.initId = id
      }
      TreeList({parentId:id,treeType:this.treeType,level:4}).then(res => {
        this.tableData = res.data.data.list
      })
    },
    // 切换下拉框
    handleChange(val){
      TreeList({parentId:val,treeType:this.treeType,level:4}).then(res => {
        this.tableData = res.data.data.list
      })
    },
    // 开始做题
    startExam(row){
      this.$store.dispatch('commons/setParam', {exampleDetailId: row.id})
      this.$store.dispatch('tagsView/delCachedViewByName', "startExam")
      this.$router.push({
        path: "/example/startExam",
      });
    },
    // 切换题目类型
    changeTimuleixing(item){
      this.isActive = item.treeType;
      this.treeType = item.treeType
      this.timuLeixingName = item.name
      TreeList({parentId:this.value,treeType:this.treeType,level:4}).then(res => {
        this.tableData = res.data.data.list
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  color: red;
}
</style>