import request from '@/utils/request'

// 目录列表
export function TreeList(data) {
  return request({
    url: 'api/v2/m_Tk/TreeList',
    method: 'post',
    data
  })
}

// 题目列表
export function questionBankList(data) {
  return request({
    url: 'api/v2/m_Tk/questionBankList',
    method: 'post',
    data
  })
}